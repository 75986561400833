import styled from '@emotion/styled';
import * as React from 'react';
import { Constants } from '../../utility/Constants';
import { Utility } from '../../utility/Utility';
import { Container } from 'react-bootstrap';
import { PageLayout } from './PageLayout';
import { Gallery } from '../Gallery';

export const LINK_PATH = '/blog-post/';

const BlogPost = ({ pageContext }: any) => {
  const { blogPost, slug } = pageContext;

  return (
    <PageLayout
      browserTitle={`${blogPost.title} - Blog - ${Constants.PAGE_SHORT_TITLE}`}
      title={blogPost.title}
      metaDescription={blogPost.title}
      blocked="true"
      background={blogPost.image}
      date={blogPost.date}
    >
      <>
        {
          Utility.contentToString(
            JSON.parse(blogPost.content.raw),
            blogPost.content.references
          ) as any[]
        }
      </>
      {blogPost.gallery == null ? (
        <div></div>
      ) : (
        <Gallery photos={blogPost.gallery} />
      )}
    </PageLayout>
  );
};

const ContentPanel = styled(Container)`
  background: white;
  min-height: 30vh;
  padding-top: 50px;
`;

export default BlogPost;
