import styled from '@emotion/styled';
import * as React from 'react';
import { keyframes } from '@emotion/react';
import { BsArrowDownCircle } from 'react-icons/bs';
import { useStaticQuery, graphql } from 'gatsby';
import { Container } from 'react-bootstrap';

interface Props {
  scrollToElement: React.RefObject<HTMLDivElement>;
}

const ImagePanel = ({ scrollToElement }: Props) => {
  const scrollDown = React.useCallback(() => {
    (scrollToElement.current as any).scrollIntoView();
  }, []);

  const data = useStaticQuery<GatsbyTypes.ImagePanelBackgroundQuery>(graphql`
    query ImagePanelBackground {
      allContentfulAsset(
        filter: { id: { eq: "ba5fb42f-d17f-5952-b184-2a755ac0789e" } }
      ) {
        nodes {
          publicUrl
          title
        }
      }
    }
  `);

  const background = data.allContentfulAsset.nodes[0].publicUrl;

  return (
    <ImageContainer fluid background={background}>
      <Bouncer onClick={scrollDown}>
        <StyledBsArrowDownCircle />
        <p>Zobacz trasę</p>
      </Bouncer>
    </ImageContainer>
  );
};

const StyledBsArrowDownCircle = styled(BsArrowDownCircle)`
  width: 80px;
  height: 80px;
`;

const bounceAnimation = keyframes`
    0% { transform: scale(1,1)      translateY(0); }
   10%  { transform: scale(1.1,.9)   translateY(0); }
   30%  { transform: scale(.9,1.1)   translateY(-100px); }
   50%  { transform: scale(1.05,.95) translateY(0); }
   57%  { transform: scale(1,1)      translateY(-7px); }
   64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }  
`;

const Bouncer = styled.button`
  position: absolute;
  left: calc(50% - 50px);
  bottom: 5%;
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
  color: white;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  font-size: 20px;

  animation-name: ${bounceAnimation};
  animation-timing-function: ease;
`;

const ImageContainer = styled(Container)<{ background: string }>`
  background-image: url('${(props) => props.background}');
  background-option: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  width: 100%;
  height: 100vh;
  display: flex;
`;

export default ImagePanel;
