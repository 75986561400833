import styled from '@emotion/styled';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import { BsFacebook, BsInstagram } from 'react-icons/bs';
import { PageMenu } from './PageMenu';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Constants } from '../utility/Constants';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { FaColumns } from 'react-icons/fa';

export const IndexRow = ({ children, top }: any) => {
  return (
    <>
      <StyledContainer top={top}>{children}</StyledContainer>
      <div
        style={{
          width: '100%',
          height: '1px',
          backgroundColor: '#212121',
          marginTop: '32px',
          marginBottom: '16px'
        }}
      ></div>
    </>
  );
};

const StyledContainer = styled(Container)<{ top?: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: ${(props) => (props.top == 1 ? 'top' : 'center')};

  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
      padding-bottom: 24px;
    }
  }
`;
