import styled from '@emotion/styled';
import React from 'react';
import { VscChromeClose } from 'react-icons/vsc';

type Props = {
  children?: any;
  title?: string;
  content?: string;
  closeCallback?: React.MouseEventHandler<SVGElement>;
};

export const SpeechBubble = ({ title, content, closeCallback }: Props) => {
  return (
    <ComponentDiv>
      <ContentContainer>
        <Title>{title}</Title>
        <Content>
          <i>stan: </i>
          {content}
        </Content>
      </ContentContainer>
      <StyledVscChromeClose onClick={closeCallback} />
    </ComponentDiv>
  );
};

const StyledVscChromeClose = styled(VscChromeClose)`
  position: absolute;
  left: 10px;
  top: 10px;
`;

const Title = styled.p`
  font-size: 20px;
`;
const Content = styled.p`
  font-weight: normal;
`;

const ComponentDiv = styled.div`
  position relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 75px;
  background: #00bfb6;
  font-weight: 900;
  color: #fff;
  font-family: arial;
  position: relative;

  &:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #00bfb6;
    border-top: 10px solid #00bfb6;
    border-bottom: 10px solid transparent;
    right: 50%;
    bottom: -19px;
  }
`;

const ContentContainer = styled.div`
  > p {
    margin-bottom: 0px !important;
  }
`;
