import React, { LegacyRef, useCallback, useRef, useState } from 'react';
import { geoCentroid } from 'd3-geo';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation
} from 'react-simple-maps';
import allStates from '../data/allStates.json';
import { SpeechBubble } from './SpeechBubble';
import styled from '@emotion/styled';
import { Container } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

const offsets = {
  VT: [30, -8],
  // NH: [-34, -20],
  MA: [-5, -10],
  RI: [28, 2],
  // CT: [35, 10],
  NJ: [34, 1],
  DE: [41, 0],
  MD: [59, 10],
  DC: [20, 21]
};

const TravelMap = () => {
  const parentElement = useRef<HTMLDivElement>(null);

  const [selectedPlace, setSelectedPlace] = useState({ name: '', state: '' });
  const [bubblePosition, setBubblePosition] = useState({ left: 0, top: 0 });

  const data = useStaticQuery<GatsbyTypes.GetCityVisibleOnMapQuery>(graphql`
    query GetCityVisibleOnMap {
      allContentfulCity(
        filter: { showOnMap: { eq: true }, node_locale: { eq: "pl-PL" } }
      ) {
        nodes {
          name
          location {
            lat
            lon
          }
          state {
            name
          }
        }
      }
    }
  `);

  const onPlaceSelected = useCallback(
    (cityName, state, target) => {
      setSelectedPlace({ name: cityName, state: state });
      setBubblePosition({
        left:
          target.getBoundingClientRect().left -
          (parentElement?.current?.getBoundingClientRect().left ?? 0) -
          120,
        top:
          target.getBoundingClientRect().top -
          (parentElement?.current?.getBoundingClientRect().top ?? 0) -
          92
      });
    },
    [selectedPlace]
  );

  const clearPlaceSelection = useCallback(() => {
    setSelectedPlace({ name: '', state: '' });
  }, [selectedPlace]);

  return (
    <div
      style={{ position: 'relative', overflow: 'hidden' }}
      ref={parentElement}
    >
      <Container>
        <h2 style={{ marginTop: '24px' }}>Odwiedzone miejsca</h2>
      </Container>
      <ComposableMap projection="geoAlbersUsa">
        <Geographies geography={geoUrl}>
          {({ geographies }) => (
            <>
              {geographies.map((geo) => {
                const cur = allStates.find((s) => s.val === geo.id);
                return (
                  <Geography
                    key={geo.rsmKey}
                    stroke="#FFF"
                    geography={geo}
                    fill={
                      data.allContentfulCity.nodes
                        .map((place: any) => place.state.name)
                        .indexOf(cur?.name) !== -1
                        ? '#d995f3'
                        : ' #DEDEDE'
                    }
                    style={{
                      default: { outline: 'none' },
                      hover: { outline: 'none' },
                      pressed: { outline: 'none' }
                    }}
                  />
                );
              })}
              {geographies.map((geo) => {
                const centroid = geoCentroid(geo);
                const cur = allStates.find((s) => s.val === geo.id);

                return (
                  <g key={geo.rsmKey + '-name'}>
                    {cur &&
                      centroid[0] > -160 &&
                      centroid[0] < -67 &&
                      (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                        <Marker coordinates={centroid}>
                          <text y="2" fontSize={10} textAnchor="middle">
                            {cur.name}
                          </text>
                        </Marker>
                      ) : (
                        <Annotation
                          subject={centroid}
                          dx={offsets[cur.id as keyof typeof offsets][0]}
                          dy={offsets[cur.id as keyof typeof offsets][1]}
                          connectorProps={{
                            stroke: '#212121',
                            strokeWidth: 1,
                            strokeLinecap: 'round'
                          }}
                        >
                          <text x={4} fontSize={8} alignmentBaseline="middle">
                            {cur.name}
                          </text>
                        </Annotation>
                      ))}
                  </g>
                );
              })}
            </>
          )}
        </Geographies>
        {data?.allContentfulCity?.nodes
          ? data.allContentfulCity.nodes
              .map((originalCity: any) => {
                const result = {
                  name: originalCity.name as string,
                  coordinates: [
                    originalCity.location.lon,
                    originalCity.location.lat
                  ] as [number, number],
                  markerOffset: 0,
                  state: originalCity.state.name
                };

                return result;
              })
              .map(
                ({
                  name,
                  coordinates,
                  markerOffset,
                  state
                }: {
                  name: string;
                  coordinates: [number, number];
                  markerOffset: number;
                  state: string;
                }) => (
                  <Marker
                    key={name}
                    coordinates={coordinates}
                    onClick={(e) => onPlaceSelected(name, state, e.target)}
                  >
                    <g fill="#FF5533" transform="translate(-8, -16)">
                      <path d="M8 0c-2.761 0-5 2.239-5 5 0 5 5 11 5 11s5-6 5-11c0-2.761-2.239-5-5-5zM8 8.063c-1.691 0-3.063-1.371-3.063-3.063s1.371-3.063 3.063-3.063 3.063 1.371 3.063 3.063-1.371 3.063-3.063 3.063zM6.063 5c0-1.070 0.867-1.938 1.938-1.938s1.938 0.867 1.938 1.938c0 1.070-0.867 1.938-1.938 1.938s-1.938-0.867-1.938-1.938z" />
                    </g>
                    {/* <text
                    textAnchor="middle"
                    y={markerOffset}
                    style={{ fontFamily: 'system-ui', fill: '#5D5A6D' }}
                  >
                    {name}
                  </text> */}
                  </Marker>
                )
              )
          : null}
      </ComposableMap>
      {selectedPlace.name !== '' ? (
        <SpeechBubbleAbsoluteContainer
          left={bubblePosition.left}
          top={bubblePosition.top}
        >
          <SpeechBubble
            title={selectedPlace.name}
            content={selectedPlace.state}
            closeCallback={clearPlaceSelection}
          />
        </SpeechBubbleAbsoluteContainer>
      ) : null}
    </div>
  );
};

const SpeechBubbleAbsoluteContainer = styled.div<{ left: number; top: number }>`
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
`;

export default TravelMap;
