import styled from '@emotion/styled';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import YouTube from 'react-youtube';

export const Movies = () => {
  const data = useStaticQuery<GatsbyTypes.GetMoviesQuery>(graphql`
    query GetMovies {
      allContentfulMovie(filter: { node_locale: { eq: "pl-PL" } }, limit: 3) {
        nodes {
          title
          url
          pinned
        }
      }
    }
  `);

  const [movieOpts, setMovieOpts] = useState({
    height: '390',
    width: '640'
  });

  useEffect(() => {
    if (window.innerWidth > 1000)
      setMovieOpts({
        height: '280',
        width: '400'
      });
    else {
      if (window.innerWidth < 640)
        setMovieOpts({
          height: `${(380 / 640) * (window.innerWidth - 40)}`,
          width: `${window.innerWidth - 40}`
        });
    }
  }, []);

  return (
    <ComponentContainer>
      <h2> Najnowsze filmy</h2>
      {data.allContentfulMovie.nodes.map((item) => (
        <YouTube
          key={`${item.title} - Najnowsze filmy o fiacie 126p i jego przygodach`}
          title={item.title}
          videoId={item!.url!.split('v=')[1]}
          opts={movieOpts}
        />
      ))}
      <div style={{ textAlign: 'right', marginRight: '50px' }}>
        <Link to="/movies">Zobacz więcej filmów</Link>
      </div>
    </ComponentContainer>
  );
};

const ComponentContainer = styled(Container)`
  a {
    text-decoration: none;
    color: #212121;

    :hover {
      color: #cd0306;
    }
  }
`;
const StyledYouTube = styled(YouTube)``;
