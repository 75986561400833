import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import MainLayout from '../components/layouts/MainLayout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { Container } from 'react-bootstrap';

const NotFoundPage = () => {
  const data = useStaticQuery<GatsbyTypes.NotFound404ImageQuery>(graphql`
    query NotFound404Image {
      allContentfulAsset(
        filter: { id: { eq: "d7eae348-b64e-55d4-a8d9-de31bc3eccbd" } }
      ) {
        nodes {
          gatsbyImageData(width: 400, placeholder: BLURRED, formats: [WEBP])
          title
        }
      }
    }
  `);

  const image = getImage(data?.allContentfulAsset?.nodes[0] as any);

  return (
    <MainLayout
      title="Nie ma takiej strony"
      metaDescription="Error 404, taka strona nie istnieje"
      blocked="true"
    >
      <ContentContainer>
        <GatsbyImage
          image={image!}
          alt={'Nie znalezioneo strony!!'}
          title={'Jeszcze tutaj nie dojechaliśmy...'}
        />
        <h1 style={{ color: '#212121', margin: '24px' }}>
          Tutaj jeszcze nie dotarliśmy...
        </h1>
        <Link to="/">Wróć do strony głównej</Link>
      </ContentContainer>

      <div></div>
    </MainLayout>
  );
};

const ContentContainer = styled(Container)`
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-bottom: 32px;

  > a {
    text-decoration: none;
    color: #212121;
    :hover {
      color: #ababab
    }
  }
  }
`;
export default NotFoundPage;
