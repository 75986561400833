import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
type Props = {
  items: { content: string; num: number }[];
};

export const Circles = ({ items }: Props) => {
  const data = useStaticQuery<GatsbyTypes.GetCirclesQuery>(graphql`
    query GetCircles {
      allContentfulCircle(filter: { node_locale: { eq: "pl-PL" } }) {
        nodes {
          currentValue
          fullValue
          contentful_id
          name
          description
        }
      }
      allContentfulState(filter: { node_locale: { eq: "pl-PL" } }) {
        totalCount
      }
    }
  `);

  const [days, setDays] = useState(0);

  useEffect(() => {
    const date1 = new Date('5/9/2022');
    const date2 = new Date();
    const difference = date2.getTime() - date1.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));

    setDays(totalDays); // Close the navigation panel
  }, []);
  console.log('Data', data);

  const getValueForCircle = (item: any) => {
    switch (item.contentful_id) {
      case '1MjFzptoAbVWyCmAdoNXvK':
        return days > parseInt(item.fullValue ?? '0') ? item.fullValue : days;
      case 'gC0rNrnjgQgbzrkzZuwuY':
        return data.allContentfulState.totalCount;
      default:
        return item.currentValue;
    }
  };

  return (
    <ComponentContainer>
      <Row>
        {data.allContentfulCircle.nodes.map((item) => (
          <StyledCol key={item.name}>
            <Circle>
              <p>{`${getValueForCircle(item)} / ${item.fullValue}`}</p>
              <p>{item.description}</p>
            </Circle>
          </StyledCol>
        ))}
      </Row>
    </ComponentContainer>
  );
};

const StyledCol = styled(Col)``;

const ComponentContainer = styled(Container)``;

const Circle = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 40px;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  font-size: 50px;
  color: #000;
  text-align: center;
  border: 5px solid #cd0306;

  font-family: 'Russo One';
  margin: auto;
  margin-bottom: 24px;
`;
