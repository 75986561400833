import styled from '@emotion/styled';
import * as React from 'react';
import { Footer } from '../components/Footer';
import MainLayout from '../components/layouts/MainLayout';
import { Constants } from '../utility/Constants';
import ImagePanel from '../components/ImagePanel';
import { TravelListVertical } from '../components/TravelList/TravelListVertical';
import { Circles } from '../components/Circles';
import TravelMap from '../components/TravelMap';
import { graphql, useStaticQuery } from 'gatsby';
import { Utility } from '../utility/Utility';
import { useMemo } from 'react';
import { Paypal } from '../components/Paypal';
import { IndexRow } from '../components/IndexRow';
import { LastBlogPost } from '../components/LastBlogPost';
import { Movies } from '../components/Movies';

// markup
const IndexPage = () => {
  const data = useStaticQuery<GatsbyTypes.IndexPageQuery>(graphql`
    query IndexPage {
      allContentfulJourney(
        filter: {
          node_locale: { eq: "pl-PL" }
          id: { eq: "4f8fec9c-4c9e-5723-8135-375bd81b2a10" }
        }
      ) {
        nodes {
          name
          description {
            raw
          }
          id
        }
      }
      allContentfulState(
        filter: { node_locale: { eq: "pl-PL" } }
        sort: { fields: order }
      ) {
        nodes {
          name
          image {
            gatsbyImageData(width: 300, placeholder: BLURRED, formats: [WEBP])
          }
        }
      }
      allContentfulPlace(filter: { node_locale: { eq: "pl-PL" } }) {
        nodes {
          name
          state {
            name
          }
          content {
            raw
          }
        }
      }
    }
  `);

  const places = data.allContentfulState.nodes.map((state: any) => {
    return {
      ...state,
      places: data.allContentfulPlace.nodes.filter(
        (place: any) => place.state.name == state.name
      )
    };
  });

  const scrollToElement = React.useRef<HTMLDivElement>(null);
  const journeyDescription = useMemo(
    () =>
      Utility.contentToString(
        JSON.parse(data.allContentfulJourney.nodes[0].description?.raw ?? '')
      ),
    [data]
  );

  return (
    <main>
      <MainLayout
        title={Constants.PAGE_TITLE}
        metaDescription={Constants.DEFAULT_META_DESCRIPTION}
      >
        <ImagePanel scrollToElement={scrollToElement} />
        <div
          style={{ height: '120px', width: '100%' }}
          ref={scrollToElement}
        ></div>
        <IndexRow top={1}>
          <LastBlogPost />
          {/* <Paypal /> */}
          <Movies />
        </IndexRow>
        <TravelListVertical
          places={places}
          title={data.allContentfulJourney.nodes[0].name ?? ''}
          journeyDescription={journeyDescription}
        />
        <StyledTravelMap>
          <TravelMap />
        </StyledTravelMap>
        <div style={{ marginBottom: '100px' }}>
          <Circles
            items={[
              { content: 'Stanów', num: 16 },
              { content: 'km podróży', num: 8000 },
              { content: 'Dni', num: 50 }
            ]}
          />
        </div>
      </MainLayout>
    </main>
  );
};

const StyledTravelMap = styled.div`
  border: 1px solid black;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const PageContainer = styled.div``;

export default IndexPage;
