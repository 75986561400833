import styled from '@emotion/styled';
import React from 'react';
import { forwardRef, LegacyRef, useCallback, useState } from 'react';

import { TravelListItem } from './TravelListItem';
import { TravelPlacesNavigator } from './TravelPlacesNavigator';

type Props = {
  places: any[];
  journeyDescription: HTMLElement[];
  title: string;
};

export const TravelListVertical = forwardRef(
  (
    { places, journeyDescription, title }: Props,
    ref: LegacyRef<HTMLDivElement>
  ) => {
    const [currentItem, setCurrentItem] = useState(0);

    const nextElement = useCallback(() => {
      if (!places) return;
      const newIndex = currentItem + (currentItem < places.length - 1 ? 1 : 0);
      setCurrentItem(newIndex);
    }, [places, currentItem]);

    const prevElement = useCallback(() => {
      if (!places) return;
      const newIndex = currentItem - (currentItem > 0 ? 1 : 0);
      setCurrentItem(newIndex);
    }, [places, currentItem]);

    return (
      <ComponentContainer ref={ref}>
        <Title>{title}</Title>
        <ContentContainer>
          <TravelDescriptionContainer>
            {journeyDescription}
          </TravelDescriptionContainer>
          <TravelPlacesContainer>
            <TravelPlacesNavigator
              nextItem={() => nextElement()}
              prevItem={() => prevElement()}
              nextDisable={places && currentItem > places.length - 1}
              prevDisable={currentItem <= 0}
            />
            <StyledDiv>
              <ItemsContainer>
                <ItemAnimationContainer
                  width={`${!places ? 0 : places.length * 500}px`}
                  position={currentItem * -400}
                >
                  {!places
                    ? null
                    : places.map((place, index) => (
                        <TravelListItem
                          key={`place-${index}-${place.name}`}
                          place={place}
                          onSelectCallback={() => setCurrentItem(index)}
                        />
                      ))}
                </ItemAnimationContainer>
              </ItemsContainer>
            </StyledDiv>
          </TravelPlacesContainer>{' '}
        </ContentContainer>
      </ComponentContainer>
    );
  }
);

const StyledDiv = styled.div`
  flex: 5;
  overflow: 'hidden';
  @media (max-width: 768px) {
    overflow: visible;
  }
`;

const TravelPlacesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  height: 500px;
`;

const TravelDescriptionContainer = styled.div`
  color: #212121;
  flex: 1;
  margin-right: 40px;
  padding-top: 42px;
  background: white;
  z-index: 10;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: black;
  margin-top: 20px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ItemAnimationContainer = styled.div<{ position: number; width: string }>`
  position: absolute;
  left: ${(props) => props.position}px;
  transition: left 1.5s ease-in-out;
  width: ${(props) => props.width};
  display: flex;

  & > :not(:first-of-type) {
    margin-left: 80px;
  }
`;

const ItemsContainer = styled.div`
  position: relative;
  float: left;
  height: 440px;
`;

const ComponentContainer = styled.div`
  min-height: 75vh;
  color: white;
  overflow: hidden;
  margin-right: 0px;
  margin-left: 100px;

  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin: 0 24px 50px 24px;
    min-height: 150vh;
  }
`;

const Title = styled.h1`
  color: #666;
  margin-top: 60px;
`;
