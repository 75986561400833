/* eslint-disable @typescript-eslint/ban-types */
import styled from '@emotion/styled';
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill
} from 'react-icons/bs';
import { MouseEventHandler } from 'react';

type Props = {
  nextItem: MouseEventHandler<SVGElement>;
  prevItem: MouseEventHandler<SVGElement>;
  nextDisable: boolean;
  prevDisable: boolean;
};

export const TravelPlacesNavigator = ({
  nextItem,
  prevItem,
  nextDisable,
  prevDisable
}: Props) => {
  return (
    <ComponentContainer>
      <StyledNext onClick={nextItem} disable={`${nextDisable}`} />
      <StyledPrev onClick={prevItem} disable={`${prevDisable}`} />
    </ComponentContainer>
  );
};

const StyledNext = styled(BsFillArrowLeftCircleFill)<{ disable: string }>`
  color: ${(props) => (props.disable === 'true' ? 'grey' : ' orange')};
`;

const StyledPrev = styled(BsFillArrowRightCircleFill)<{ disable: string }>`
  color: ${(props) => (props.disable === 'true' ? 'grey' : ' orange')};
  margin-left: 8px;
`;

const ComponentContainer = styled.div`
  color: orange;
  font-size: 40px;
  margin-top: -14px;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`;
