import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import { Utility } from '../utility/Utility';
import { LINK_PATH as PLACE_PATH } from './layouts/Place';
import { LINK_PATH as BLOG_PATH } from './layouts/BlogPost';

type Props = {
  post?: any;
};

export const PostListItem = ({ post }: Props) => {
  const image = useMemo(() => getImage(post.image as any) as any, [post]);
  const linkUrl = `${
    post.name ? PLACE_PATH : BLOG_PATH
  }${Utility.changeTextToSlug(post.name ?? post.title)}`;
  return (
    <PostContainer>
      <PostImage
        image={image}
        alt={post.image.title}
        title={post.image.title}
      />
      <PostTextPanel>
        <Link to={linkUrl}>
          <h2>{post.name ?? post.title}</h2>
        </Link>
        <DateP>
          {new Intl.DateTimeFormat('pl-pl', {
            month: 'long',
            day: '2-digit',
            year: 'numeric'
          }).format(new Date(post.date ?? post.updatedAt))}
        </DateP>
        <p>{post.short}</p>
        {post.city ? (
          <p>
            <b>Miasto: {post.city.name}</b> <br />
            <b>Stan: {post.state.name}</b>
          </p>
        ) : null}
        <Link to={linkUrl}>
          <ReadMore>Read more</ReadMore>
        </Link>
      </PostTextPanel>
    </PostContainer>
  );
};

const ReadMore = styled.p`
  font-family: 'Russo One', sans-serif;
  font-size: 18px;
  text-align: right;
  margin-bottom: 0px;

  :hover {
    color: #ababab;
  }

  a {
    text-decoration: none;
  }
`;

const DateP = styled.p`
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 4px;
`;

const PostImage = styled(GatsbyImage)`
  flex: 1;
  max-width: 50%;
  border-radius: 0px 25px 0px 25px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const PostTextPanel = styled.div`
  flex: 1;
  margin: 16px;

  h2 {
    font-size: 24px !important;

    :hover {
      color: #ababab;
    }
  }
  a {
    text-decoration: none;
  }
`;

const PostContainer = styled.div`
  min-height: 300px;
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  border-radius: 0px 25px 0px 25px;

  max-width: 800px;
  margin: auto;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;
