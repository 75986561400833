import styled from '@emotion/styled';
import * as React from 'react';
import { Footer } from '../components/Footer';
import '../styles/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainLayout from '../components/layouts/MainLayout';
import { Constants } from '../utility/Constants';
import ImagePanel from '../components/ImagePanel';
import { TravelListVertical } from '../components/TravelList/TravelListVertical';
import { Circles } from '../components/Circles';
import TravelMap from '../components/TravelMap';
import { graphql, useStaticQuery } from 'gatsby';
import { Utility } from '../utility/Utility';
import { useMemo } from 'react';
import { PostListItem } from '../components/PostListItem';
import { Container } from 'react-bootstrap';
import { ImageTitlePanel } from '../components/ImageTitlePanel';
import { PageLayout } from '../components/layouts/PageLayout';

// markup
const BlogPage = () => {
  const data = useStaticQuery<GatsbyTypes.BlogQuery>(graphql`
    query Blog {
      allContentfulPlace(
        filter: { node_locale: { eq: "pl-PL" }, content: { raw: { ne: null } } }
      ) {
        nodes {
          name
          short
          gallery {
            gatsbyImageData
            title
          }
          image {
            gatsbyImageData(width: 1200, placeholder: BLURRED, formats: [WEBP])
            title
          }
          city {
            name
          }
          state {
            name
          }
          updatedAt
        }
      }
      allContentfulBlogPost(
        filter: { node_locale: { eq: "pl-PL" }, content: { raw: { ne: null } } }
      ) {
        nodes {
          short
          title
          updatedAt
          date
          content {
            raw
            references {
              ... on ContentfulAsset {
                id
                gatsbyImageData
                contentful_id
                title
              }
            }
          }
          image {
            gatsbyImageData
            title
          }
          gallery {
            gatsbyImageData
            title
          }
        }
      }
      allContentfulAsset(
        filter: {
          node_locale: { eq: "pl-PL" }
          id: { eq: "c8b4cac7-7c5c-5c60-8e83-b30467a40b17" }
        }
      ) {
        nodes {
          title
          gatsbyImageData
        }
      }
    }
  `);

  const places = data.allContentfulPlace.nodes;
  const blogposts = data.allContentfulBlogPost.nodes;
  let posts = [];
  Array.prototype.push.apply(posts, places);
  Array.prototype.push.apply(posts, blogposts);
  posts.sort((obj1, obj2) => {
    const result =
      Date.parse(obj2.date ?? obj2.updatedAt) >
      Date.parse(obj1.date ?? obj1.updatedAt)
        ? 1
        : -1;
    return result;
  });

  return (
    <PageLayout
      title={`Nasz blog - ${Constants.PAGE_SHORT_TITLE}`}
      metaDescription={
        'Blog zawiera listę wszystkich wpisów, przedstawiających nasze przygody podczas podróżowania. Mamy nadzieje, że wspólne przeżywanie da Wam tyle samo radości co nam dało przygotowanie tych treści dla Was. Zapraszamy do lektury.'
      }
      blocked="true"
      background={data.allContentfulAsset.nodes[0]}
    >
      {posts.map((post: any, index: number) => (
        <PostListItem key={`post-${index}`} post={post} />
      ))}
    </PageLayout>
  );
};

const ContentPanel = styled(Container)`
  background: white;
  min-height: 30vh;
  padding-top: 50px;
`;

export default BlogPage;
