exports.components = {
  "component---src-components-layouts-blog-post-tsx": () => import("./../../../src/components/layouts/BlogPost.tsx" /* webpackChunkName: "component---src-components-layouts-blog-post-tsx" */),
  "component---src-components-layouts-page-tsx": () => import("./../../../src/components/layouts/Page.tsx" /* webpackChunkName: "component---src-components-layouts-page-tsx" */),
  "component---src-components-layouts-place-tsx": () => import("./../../../src/components/layouts/Place.tsx" /* webpackChunkName: "component---src-components-layouts-place-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-movies-tsx": () => import("./../../../src/pages/movies.tsx" /* webpackChunkName: "component---src-pages-movies-tsx" */)
}

