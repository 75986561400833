import styled from '@emotion/styled';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { SimplePostItem } from './SimplePostItem';

type Props = {
  children?: any;
};

export const LastBlogPost = ({}: Props) => {
  const data = useStaticQuery<GatsbyTypes.LastBlogQuery>(graphql`
    query LastBlog {
      allContentfulPlace(
        limit: 2
        sort: { fields: createdAt, order: DESC }
        filter: { node_locale: { eq: "pl-PL" }, content: { raw: { ne: null } } }
      ) {
        nodes {
          name
          short
          gallery {
            gatsbyImageData
            title
          }
          image {
            gatsbyImageData(width: 1200, placeholder: BLURRED, formats: [WEBP])
            title
          }
          city {
            name
          }
          state {
            name
          }
          updatedAt
        }
      }
      allContentfulBlogPost(
        filter: { node_locale: { eq: "pl-PL" }, content: { raw: { ne: null } } }
      ) {
        nodes {
          short
          title
          updatedAt
          date
          content {
            raw
            references {
              ... on ContentfulAsset {
                id
                gatsbyImageData
                contentful_id
                title
              }
            }
          }
          image {
            gatsbyImageData
            title
          }
          gallery {
            gatsbyImageData
            title
          }
        }
      }
      allContentfulAsset(
        filter: {
          node_locale: { eq: "pl-PL" }
          id: { eq: "c8b4cac7-7c5c-5c60-8e83-b30467a40b17" }
        }
      ) {
        nodes {
          title
          gatsbyImageData
        }
      }
    }
  `);

  const places = data.allContentfulPlace.nodes;
  const blogposts = data.allContentfulBlogPost.nodes;
  let posts = [];
  Array.prototype.push.apply(posts, places);
  Array.prototype.push.apply(posts, blogposts);
  posts.sort((obj1, obj2) => {
    const result =
      Date.parse(obj2.date ?? obj2.updatedAt) >
      Date.parse(obj1.date ?? obj1.updatedAt)
        ? 1
        : -1;

    return result;
  });
  posts = posts.slice(0, 3);

  return (
    <StyledPanel>
      <h2> Ostatnie wpisy na blogu</h2>
      <ul>
        {posts.map((post: any, index: number) => (
          <li key={`post-${index}`}>
            <SimplePostItem post={post} />
          </li>
        ))}
      </ul>
      <div style={{ textAlign: 'right', marginRight: '50px' }}>
        <Link to="/blog">Zobacz więcej wpisów na blogu</Link>
      </div>
    </StyledPanel>
  );
};

const StyledPanel = styled.div`
  > ul li {
    display: block;
    text-decoration: none;
    padding-right: 0;
  }
  a {
    text-decoration: none;
    color: #212121;

    :hover {
      color: #cd0306;
    }
  }
`;
