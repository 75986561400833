import styled from '@emotion/styled';
import * as React from 'react';
import { Footer } from '../components/Footer';
import '../styles/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainLayout from '../components/layouts/MainLayout';
import { Constants } from '../utility/Constants';
import ImagePanel from '../components/ImagePanel';
import { TravelListVertical } from '../components/TravelList/TravelListVertical';
import { Circles } from '../components/Circles';
import TravelMap from '../components/TravelMap';
import { graphql, useStaticQuery } from 'gatsby';
import { Utility } from '../utility/Utility';
import { useMemo } from 'react';
import { PostListItem } from '../components/PostListItem';
import { Container } from 'react-bootstrap';
import { ImageTitlePanel } from '../components/ImageTitlePanel';
import { PageLayout } from '../components/layouts/PageLayout';
import YouTube from 'react-youtube';

// markup
const MoviePage = () => {
  const data = useStaticQuery<GatsbyTypes.MoviesQuery>(graphql`
    query Movies {
      allContentfulMovie(filter: { node_locale: { eq: "pl-PL" } }) {
        nodes {
          title
          url
          pinned
        }
      }
      allContentfulAsset(
        filter: { id: { eq: "5a704076-bc30-5400-a542-698ca207ad7d" } }
      ) {
        nodes {
          title
          gatsbyImageData
        }
      }
    }
  `);

  const movies = data.allContentfulMovie.nodes;

  return (
    <PageLayout
      title={`Filmy - ${Constants.PAGE_SHORT_TITLE}`}
      metaDescription={
        'Zbiór filmów z podrózy maluchem. Zobacz świat z perspektywy fotela fiata 126p.'
      }
      blocked="true"
      background={data.allContentfulAsset.nodes[0]}
    >
      <ContentComponent>
        {movies.map((movie: any, index: number) => (
          <YouTube
            key={`Fiat 126 on route 66 movie no ${index} - ${movie.title}`}
            title={movie.title}
            videoId={movie.url.split('v=')[1]}
          />
        ))}
      </ContentComponent>
    </PageLayout>
  );
};

const ContentComponent = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 10px;
  }
`;

export default MoviePage;
