import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useCallback, useState } from 'react';
import Card from 'react-bootstrap/esm/Card';
import { SiGooglemaps } from 'react-icons/si';
import { Utility } from '../../utility/Utility';
import { LINK_PATH as PLACE_PATH } from '../layouts/Place';

type Props = {
  place: any;
  style?: React.CSSProperties;
  opened?: boolean;
  onSelectCallback: React.MouseEventHandler<HTMLElement>;
};

export const TravelListItem = ({
  place,
  style,
  onSelectCallback,
  opened = false
}: Props) => {
  const [hasPlaces] = useState(place.places !== undefined);
  const [isOpened, setIsOpened] = useState(false || opened);

  const selectCallback = useCallback(
    (event) => {
      setIsOpened(hasPlaces && !isOpened);
      onSelectCallback(event);
    },
    [isOpened]
  );

  const background = getImage(place.image);

  return (
    <Wrapper hasCities={`${hasPlaces}`} style={style}>
      {hasPlaces ? (
        <CitiesContainer>
          <CitiesTitle>Odwiedzone miejsca:</CitiesTitle>
          <ul>
            {place.places.map((city: any) =>
              city.content ? (
                <Link
                  to={`${PLACE_PATH}${Utility.changeTextToSlug(city.name)}`}
                  title={`Zobacz: ${city.name}`}
                  key={`link ${city.name}`}
                >
                  <li key={city.name}>{city.name}</li>
                </Link>
              ) : (
                <li key={city.name}>{city.name}</li>
              )
            )}
          </ul>
        </CitiesContainer>
      ) : null}
      <StyledCard isopened={`${isOpened}`} onClick={selectCallback}>
        <StyledGatsbyImage
          image={background!}
          alt={place.name}
          title={place.name}
        />
        <StyledCardBody>
          <div style={{ height: '350px' }}></div>
          <BottomPanel>
            <StyledIcon />
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'end'
              }}
            >
              <div>
                <StyledCardTitle>{place.name}</StyledCardTitle>
                {place.places && place.places.length > 0 ? (
                  <StyledCardSubTitle>Odwiedzone miejsca</StyledCardSubTitle>
                ) : null}
              </div>
            </div>
          </BottomPanel>
        </StyledCardBody>
      </StyledCard>
    </Wrapper>
  );
};

const StyledCardTitle = styled(Card.Title)``;
const StyledCardSubTitle = styled.p``;

const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  height: 100%;
  min-width: 100%;
  overflow: hidden;
  > img {
    height: 100%;
    overflow: hidden;
  }
  z-index: 0;
`;

const StyledIcon = styled(SiGooglemaps)`
  font-size: 40px;
  margin-top: 10px;
  margin-right: 20px;
`;

const CitiesTitle = styled.p`
  color: #121212;
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 18px;
`;

const BottomPanel = styled.div`
  display: flex;
  direction: row;
`;

const StyledCardBody = styled(Card.Body)`
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  height: 450px;
  z-index: 2;
`;

const Wrapper = styled.div<{ hasCities: string }>`
  height: 450px;
  position: relative;
  width: 20rem;
  float: left;
  overflow: hidden;
  border-radius: 15px;
`;

const CitiesContainer = styled.div`
  position: absolute;
  background-color: #f6f4d2;
  color: #212121;
  width: 20rem;
  height: 400px;
  padding-left: 100px;

  > ul {
    padding-left: 0;
  }
  > ul li {
    display: block;
    text-decoration: none;
    padding-right: 0;
  }

  a {
    text-decoration: none;

    color: #555555;

    &:hover {
      color: red;
    }
  }
`;

const StyledCard = styled(Card)<{ isopened: string }>`
  position: absolute;
  width: 20rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  color: white;
  left: ${(props) => (props.isopened === 'true' ? -230 : 0)}px;

  transition: left 1s ease-in-out;
`;
